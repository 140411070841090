import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

declare global {
    interface Window {
        OrboSmartCapture: any;
    }
}
import { UserService } from '../shared/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Subject, Observable } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
    @ViewChild('smartCaptureContainer')
    public smartCaptureContainer: ElementRef<HTMLDivElement>;
    access_token: string = "";
    user_id = null;
    results = false;
    loader = false;
    reader: FileReader = new FileReader;
    previewImage = new Image();
    url: String | SafeUrl = './../assets/images/bald.jpg';
    data: string;
    imageUrl: string;
    imgFile = undefined;
    outputImage: any = {};
    outputData: any = {};
    images: any = {};
    topPriorities: any = {};
    routineInfo: any = {};
    isImage: boolean;
    isGuidelines: boolean = true;
    showButton = true;
    code: number;
    toggle: boolean = false;
    isBaldSelected = false;
    selectedBeardId: number | null = null;

    beards = [
        { id: 1, src: './../../assets/images/beard/1.png', alt: 'Beard 1' },
        { id: 2, src: './../../assets/images/beard/2.png', alt: 'Beard 2' },
        { id: 3, src: './../../assets/images/beard/3.png', alt: 'Beard 3' }
    ];

    // toggle webcam on/off
    public showWebcam = false;


    public dataImage = {}

    constructor(private _userService: UserService, private _toastr: ToastrService, private route: ActivatedRoute,
        private sanitizer: DomSanitizer
    ) { }

    ngOnInit() {
        // this.user_id = localStorage.getItem('userId');
        // access_token = localStorage.getItem('userToken');
        // if (localStorage.getItem('userToken') == null) {
        //     this._router.navigate(['/login']);
        // }

        this.route.queryParams.subscribe(params => {
            this.access_token = params['access_token'];
            // console.log(this.access_token);
        });


        // WebcamUtil.getAvailableVideoInputs()
        //     .then((mediaDevices: MediaDeviceInfo[]) => {
        //         this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
        //     });

    }

    getToken(event: any) {
        console.log('From get: ', event.target.value);
        this.access_token = event.target.value;
    }

    setToken() {
        console.log('From set: ', this.access_token);
        // document.cookie = 'token=' + this.token + '; domain=' + CONSTANTS.DOMAIN;
        // document.cookie = 'token=' + this.access_token;
        this._toastr.success('Token has been set!');
    }

    async startWebcam() {
        this.toggle = false;
        this.url = "";
        this.results = false;
        this.isImage = false;
        this.outputImage = "";
        this.showWebcam = true;
        const capturedPic = await window.OrboSmartCapture.capturePic(this.smartCaptureContainer.nativeElement);
        this.handleImage(capturedPic);
        this.showWebcam = false;
    }

    public handleCapture() {
        this.isGuidelines = false;
    }

    public handleImage(capturedPic: Blob): void {
        this.url = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(capturedPic)); // Set the data URL

        const date = new Date().valueOf();
        let text = '';
        const possibleText = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            text += possibleText.charAt(Math.floor(Math.random() * possibleText.length));
        }
        const imageName = date + '.' + text + '.jpeg';

        this.imgFile = new File([capturedPic], imageName, { type: 'image/jpeg' });
        this.isImage = true;
    }


    dataURItoBlob(dataURI) {
        const byteString = window.atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], { type: 'image/jpeg' });
        return blob;
    }

    changeImage(event) {
        if (event.target.files && event.target.files[0]) {
            const reader = new FileReader();

            reader.readAsDataURL(event.target.files[0]); // read file as data url
            this.imgFile = event.target.files[0];

            reader.onload = (event) => { // called once readAsDataURL is completed
                this.url = (<FileReader>event.target).result as string;
            };
            this.isImage = true;
        }
    }

    toggleBald() {
        this.isBaldSelected = !this.isBaldSelected;
        if (this.isBaldSelected === true && this.selectedBeardId === null) {
            this.selectedBeardId = 3;
        }
    }

    toggleBeard(beardId: number) {
        if (this.selectedBeardId !== beardId) {
            this.selectedBeardId = beardId;
        }
    }

    applyBeardEffect() {
        this.loader = true;
        if (this.isBaldSelected) {
            this.code = 10;
        }
        if (!this.isBaldSelected && this.selectedBeardId) {
            this.code = 11;
        }

        if (!this.imgFile) {
            this.results = false;
            this.loader = false;
            this._toastr.error('Upload an Image file', 'Uh Oh!');
        } else {
            this._userService.analyzeBeard(this.imgFile, this.code, this.selectedBeardId, this.access_token).subscribe((data) => {
                // this.loader = false;
                // this.toggle = true;
                // this.results = true;
                // this.isImage = true;
                // this.outputImage = data['data']['result_url'];
                this.showResult(data['data']['result_url']);

                // console.log(this.outputImage)
            },
                (err: HttpErrorResponse) => {
                    if (err && err.status) {
                        this._toastr.error(err.statusText, err.status.toString());
                    }
                    this._toastr.error(err.error.error.message, err.error.error.code);
                    this.loader = false;
                    this.toggle = false;
                    this.results = false;
                    this.imgFile = undefined;
                    this.isImage = false;
                });
        }
    }

    private showResult(resultUrl) {
        this.loader = false;
        this.toggle = true;
        this.results = true;
        this.isImage = true;
        this.outputImage = resultUrl;
    }

    toggleImages() {
        this.toggle = !this.toggle;
    }

}
